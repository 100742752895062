import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "@lingui/macro";
import { styled, media, angle } from "../../styles";
import {
  Page,
  Content,
  TitleImage,
  Link,
  Accordeon,
  AccordeonItem,
  AccordeonTitle,
  AccordeonBody
} from "../../components";
import musicImg from "./assets/Illu_Music.svg";
import CableAni from "./assets/Cable_ani.inline.svg";

export const MusicPage: React.FC<{}> = props => {
  const {
    cowbellImg: {
      image: {
        fluid: { src: cowbellImg }
      }
    },
    synthiImg: {
      image: {
        fluid: { src: synthiImg }
      }
    }
  }: {
    cowbellImg: {
      image: {
        fluid: {
          src: string;
        };
      };
    };
    synthiImg: {
      image: {
        fluid: {
          src: string;
        };
      };
    };
  } = useStaticQuery(graphql`
    query {
      cowbellImg: file(relativePath: { eq: "music/cowbell.png" }) {
        image: childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      synthiImg: file(relativePath: { eq: "music/MPC.png" }) {
        image: childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Page
        title="Music"
        seoTitle="Musiklabel | Consulting & Licensing | German Wahnsinn Records"
        keywords={[
          "German Wahnsinn Records",
          "Musiklabel",
          "Musikstudio",
          "Music Consulting",
          "Music Licensing",
          "Labelmanagement",
          "Musik",
          "Lizenzierung",
          "Rechteklärung",
          "Tonstudio",
          "Aufnahmestudio"
        ]}
        description="Wir labeln euch das: Wir machen mit und für euch Music Consulting & Licensing – (fast) alle Genres, am liebsten außergewöhnlich & gut."
        bgPaper="dot"
        headerSkull="Music"
        {...props}
      >
        <MusicContent>
          <MusicIntroContainer>
            <MusicTitleImage src={musicImg} title="Music" />

            <MusicCopy>
              <Trans>
                Du hättest gerne 1 Musik?
                <br />
                Super, da lässt sich auf jeden Fall was machen. Komm mit in
                unsere Fachwerkstatt für Kompositionen und Komplimente. Hier hat
                keiner Angst vor Country oder Western, denn unser kreatives
                Kollektiv setzt sich genreübergreifend aus verschiedensten
                Komponisten und Sounddesignern zusammen.{" "}
                <Link to="/work/commercials/">Werbung</Link>,{" "}
                <Link to="/work/arvrtechnology/">VR/AR</Link>,{" "}
                <Link to="/specials/">Games</Link>,{" "}
                <Link to="/work/audiobooks/">Hörspiele</Link>,{" "}
                <Link to="/work/film/">Film</Link>,{" "}
                <Link to="/work/music-recording/">Tonträger</Link> oder{" "}
                <Link to="/work/composition/">Corporate Sound</Link> – alles gar
                kein Problem.
              </Trans>
            </MusicCopy>
          </MusicIntroContainer>

          <MusicAccordeonContainer>
            <Accordeon>
              <AccordeonItem expanded={true}>
                <AccordeonTitle>
                  <Trans>Music Consulting & Licensing</Trans>
                </AccordeonTitle>
                <AccordeonBody>
                  <Trans>
                    Es mag ja gelegentlich vorkommen, dass du eine Musik super
                    gern magst, die gar nicht von uns ist, sondern von einem
                    bekannten Star. Zum Beispiel von ......... (hier bitte
                    deinen Lieblingskünstler einsetzen). Da sagen wir: Sehr
                    gern! Gar kein Problem! Wir sind nicht eitel und stehen
                    drüber. Nein, wir stehen sogar drauf! Denn wir sind auch
                    ausgewiesene Experten im Lizenzieren von Musik!
                    International bekannte Supermusiker? Null Hemmungen.
                    Rechteklärung, vertragliche Abwicklung?
                    <br />
                    Zackbumm – alles wird geregelt.
                  </Trans>
                </AccordeonBody>
              </AccordeonItem>

              <AccordeonItem>
                <AccordeonTitle>
                  <Trans>German Wahnsinn Records</Trans>
                </AccordeonTitle>
                <AccordeonBody>
                  <Trans>
                    Wir sind erfahrene Musiker und kennen uns auch noch mit
                    Rechten und Verträgen aus? Dann liegt das hier wohl nahe:
                    Wir sind auch ein Musiklabel!
                    <br />
                    #micdrop
                    <br />
                    #micwiederaufheb
                    <br />
                    Wir übernehmen gerne Produktion, Veröffentlichung und
                    Vertrieb von Musik ausgewählter Künstler, nicht nur, aber
                    vor allem aus Hamburg. Für unterschiedliche Genres und
                    außergewöhnliche Ideen stehen die Studiotore immer weit
                    offen. Es ist ja auch alles da: Tonstudio, Labelmanagement,
                    PR, halbvoller Kühlschrank – alles, was man braucht, um
                    Musik direkt aus dem Studio ohne Umwege dem Publikum
                    zugänglich zu machen – übrigens sowohl physisch für den
                    stationären Handel als auch digital für sämtliche
                    Online-Plattformen.
                    <br />
                    Unsere Studios an der Reeperbahn sind dabei ein guter Ort,
                    um Genres und Branchen ein bisschen zu mixen. So bringen wir
                    immer wieder gern unsere Partner aus der Werbe- und
                    Filmbranche mit unseren Künstlern zusammen.
                  </Trans>
                </AccordeonBody>
              </AccordeonItem>
            </Accordeon>
          </MusicAccordeonContainer>

          <Synthie src={synthiImg} />
          <Cable alt="Cable" />

          <Cowbell alt="Cowbell" src={cowbellImg} />
        </MusicContent>
      </Page>
    </>
  );
};

const MusicContent = styled(Content)``;

const MusicTitleImage = styled(TitleImage)`
  width: 100%;
  margin: 0 auto 1.5rem auto;

  ${media.tablet} {
    width: 100%;
    margin: 0 1.5rem;
  }

  ${media.laptop} {
    width: 83.3333%;
  }

  ${media.landscape} {
    width: 50%;
    margin: 0 2rem;
  }
`;

const MusicIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tablet} {
    margin-bottom: 5rem;
    width: 64%;
  }

  ${media.laptop} {
    margin-bottom: 10rem;
  }

  ${media.landscape} {
    margin-bottom: 0;
    width: 100%;
  }
`;

const MusicCopy = styled.p`
  width: 100%;
  margin: 0 0 1.5rem 0;

  ${media.tablet} {
    width: 80%;
    margin: 2rem 0 0 2rem;
    ${angle(-2)};
  }

  ${media.landscape} {
    width: 80%;
    margin: 0 10% 2rem 10%;
    ${angle(0)};
  }

  ${media.laptop} {
    width: 60%;
  }

  ${media.desktop} {
    width: 100%;
  }
`;

const MusicAccordeonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tablet} {
    flex-direction: row;
    margin: 0 25% 0 0;
    width: 60%;
    ${angle(5)};
  }

  ${media.tablet} {
    width: 70%;
    margin-left: 30%;
  }

  ${media.landscape} {
    margin: 0 10% 2rem 10%;
    width: 80%;
    ${angle(0)};
  }

  ${media.laptop} {
    width: 60%;
    margin-left: 40%;
    margin-bottom: 5rem;
  }

  ${media.desktop} {
    margin-top: 20rem;
  }
`;

const Synthie = styled.img`
  width: 330px;
  height: auto;
  max-width: none;
  top: auto;
  bottom: 0;
  right: -100px;

  ${media.tablet} {
    width: 500px;
    position: absolute;
    top: 100px;
    right: -200px;
  }

  ${media.landscape} {
    display: none;
  }

  ${media.laptop} {
    width: 750px;
    top: 150px;
    right: -300px;
    bottom: auto;
  }

  ${media.desktop} {
    width: 850px;
    right: -400px;
  }
`;

const Cable = styled(CableAni)`
  display: none;

  ${media.tablet} {
    display: block;

    position: absolute;
    z-index: -1;
    width: 100px;
    bottom: -50px;
    right: 5%;
  }

  ${media.landscape} {
    left: unset;
    bottom: 0;
  }
`;

const Cowbell = styled.img`
  display: none;

  ${media.tablet} {
    display: block;

    position: absolute;
    z-index: -1;
    width: 50%;
    bottom: -120px;
    top: 750px;
    left: -175px;
  }

  ${media.landscape} {
    display: none;
  }

  ${media.desktop} {
    /* left: -50px; */
  }
`;
